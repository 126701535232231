<template>
     <div class="container-fluid py-4 add-student offers">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">   تعديل بيانات العرض </h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form @submit="updateOffer" role="form" class="text-start mt-3">
                       <div class="row">
                <div class="mb-3 col-lg-6">
                    <label>اسم العرض<span class="red">*</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   placeholder="اسم العرض"
                   v-model='offer.name'
                  />
                </div>
                     <div class="mb-3 col-lg-6 ">
                    <label>   تاريخ انتهاء العرض<span class="red">*</span></label>
                    <div class='date'>
                  <vmd-input
                    id="date-input"
                    type="datetime-local"
                    placeholder=" تاريخ انتهاء العرض"
                   v-model='offer.expire_at'
                  />
                  </div>
                </div>
                </div>
                              <!-- subjects -->
     <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
               <div class="row">
              <div class="col-lg-6">
              <h6 class="text-white text-capitalize ps-3 float-right">مواد العرض</h6>
              </div>
               <div class="col-lg-6">
               <span @click='newSubject($event)' class="badge badge-sm  new-student pointer"><i class="fas fa-location-arrow"></i>إضافة مادة </span>
               </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0 overflow">
              <table class="table align-items-center mb-0 ">
                <thead>
                  <tr class="text-center">
                       <th
                      class="text-uppercase text-secondary  font-weight-bolder opacity-7"
                    >
                       المادة<span class="red">*</span>
                    </th>
                      <th
                      class="text-uppercase text-secondary  font-weight-bolder opacity-7"
                    >
                       سعر المادة<span class="red">*</span>
                    </th>

                      <th
                      class="text-uppercase text-secondary text-center  font-weight-bolder opacity-7"
                    >
                    </th>
                  </tr>
                </thead>
                 <tbody>
                  <tr v-for='(item,index) in allSubjects' :key='index' >
                    <td colspan="">
                      <div class="">
                        <multiselect style="margin-top:0 !important"
                          class='required form-control'
                          id='group'
                          v-model="item.subject"
                          :options='subjects'
                          :searchable='true'
                          :close-on-select='true'
                          placeholder="  اختر المادة"
                          open-direction="bottom"
                          label='name'
                          :custom-label="customLabel"
                          track-by='id'
                          required='required'
                          :internal-search="true"
                          >
                          <template v-slot:noOptions>
                            <span> فارغة </span>
                          </template>
                          <template v-slot:noResult>
                            <span>لا يوجد نتيجة </span>
                          </template>
                        </multiselect>
                      </div>
                    </td>
                    <td class="text-center" style="width:17%">
                       <vmd-input
                          id=""
                          type="number"
                          placeholder=" سعر المادة "
                          v-model='item.subjectCost'
                          @change='addSubjects'
                          required
                        />
                    </td>
                    <td class="text-center">
                     <span @click='removeSubject(index)' class="badge badge-sm bg-gradient-danger delete-btn pointer">حذف</span>
                    </td>
                  </tr>
                 </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <router-link to="/offers">
                  <vmd-button
                  type='reset'
                    class="my-4 mb-2"
                    variant="gradient"
                    color="warning"
                    > رجوع</vmd-button
                  >
                  </router-link>
                </div>
                       </div>
              </form>
        </div>
      </div>
      </div>
    </div>
    </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import $ from 'jquery'
export default {
  name: 'editOffer',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      offer: {},
      student: {},
      subjects: [],
      selectedSubject: '',
      allSubjects: [],
      errors: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}offers/${this.$route.params.id}`)
      .then((response) => {
        this.offer = response.data.data
        const allSubjects = []
        $.each(this.offer.subjects, function (key, val) {
          allSubjects.push({
            subject: {
              id: val.id,
              name: val.name
            },
            subjectCost: val.pivot.subject_cost
          })
        })
        this.allSubjects = allSubjects
      })
    const subjects = []
    this.$http.get(`${this.$hostUrl}subjects`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          subjects.push({
            name: value.name,
            id: value.id
          })
        })
        this.subjects = subjects
      })
  },
  methods: {
    newSubject ($event, index) {
      this.allSubjects.push({
        subjectCost: null,
        selectedSubject: null

      })
    },
    removeSubject (index) {
      this.allSubjects.splice(index, 1)
    },
    async updateOffer (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('name', this.offer.name)
      const sendSubject = []
      let total = 0
      for (let i = 0; i < this.allSubjects.length; i++) {
        sendSubject.push({
          subject_id: this.allSubjects[i].subject.id,
          subject_cost: this.allSubjects[i].subjectCost
        })
        total += parseInt(this.allSubjects[i].subjectCost)
      }
      formData.append('cost', total)
      formData.append('subjects', JSON.stringify(sendSubject))
      const formatDate = this.offer.expire_at.replace('T', ' ')
      formData.append('expire_at', formatDate)
      formData.append('_method', 'PUT')
      this.errors = await this.postRequest(formData, this.$hostUrl + 'offers/' + this.$route.params.id, '/offers')
    }
  }
}
</script>
<style >
.date label{
  display: flex;
    gap: 19px;
}
.overflow{
  overflow: visible !important;
}
.delete-btn{
width: 60%;
 padding: 14px !important;
}
</style>
 <style src="vue-multiselect/dist/vue-multiselect.css"></style>
